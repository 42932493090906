import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import img801 from "assets/80-1.jpg";
import img802 from "assets/80-2.jpg";
import img803 from "assets/80-3.jpg";
import img804 from "assets/80-4.jpg";
import img805 from "assets/80-5.jpg";
import img806 from "assets/80-6.jpg";
import img807 from "assets/80-7.jpg";
import img808 from "assets/80-8.jpg";
import img809 from "assets/80-9.jpg";
import img8010 from "assets/80-10.jpg";
import img8011 from "assets/80-11.jpg";
import img8012 from "assets/80-12.jpg";
import img8013 from "assets/80-13.jpg";
import img8014 from "assets/80-14.jpg";
import img8015 from "assets/80-15.jpg";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import video80 from "assets/80.mp4";

export default function Gallery() {
  const [open, setOpen] = useState(false);

  const theme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const images80 = [
    {
      original: img801,
      thumbnail: img801,
    },
    {
      original: img802,
      thumbnail: img802,
    },
    {
      original: img803,
      thumbnail: img803,
    },
    {
      original: img804,
      thumbnail: img804,
    },
    {
      original: img805,
      thumbnail: img805,
    },
    {
      original: img806,
      thumbnail: img806,
    },
    {
      original: img807,
      thumbnail: img807,
    },
    {
      original: img808,
      thumbnail: img808,
    },
    {
      original: img809,
      thumbnail: img809,
    },
    {
      original: img8010,
      thumbnail: img8010,
    },
    {
      original: img8011,
      thumbnail: img8011,
    },
    {
      original: img8012,
      thumbnail: img8012,
    },
    {
      original: img8013,
      thumbnail: img8013,
    },
    {
      original: img8014,
      thumbnail: img8014,
    },
    {
      original: img8015,
      thumbnail: img8015,
    },
  ];

  return (
    <>
      <Box name="gallery80" sx={{ height: "80vh", display: 'flex', alignItems: 'center' }}>
        <Box textAlign="center" sx={{ p: 3, width: "100%", maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
          <ImageGallery items={images80} showNav={false} />
          <IconButton variant="contained" color="primary" onClick={() => setOpen(true)}><OndemandVideoIcon /></IconButton>
        </Box>
      </Box>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <video autoPlay loop className="galleryVideo">
              <source src={video80} type="video/mp4" />
            </video>
          </DialogContent>
          <DialogActions>
            <Box align="right">
              <Button onClick={() => setOpen(false)} color="inherit">Close</Button>
            </Box>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
}






import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Public from "./layouts/Public";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "./index.css"

const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
              <CssBaseline />
    <Box>
      <Routes>
        <Route path="/*" element={<Public />} />
      </Routes>
    </Box>
    </ThemeProvider>
  );
}

export default App;

import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import img140451 from "assets/14045-1.jpg";
import img140452 from "assets/14045-2.jpg";
import img140453 from "assets/14045-3.jpg";
import img140454 from "assets/14045-4.jpg";
import img140455 from "assets/14045-5.jpg";
import img140456 from "assets/14045-6.jpg";
import img140457 from "assets/14045-7.jpg";
import img140458 from "assets/14045-8.jpg";
import img140459 from "assets/14045-9.jpg";
import img1404510 from "assets/14045-10.jpg";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import video14045 from "assets/14045.mp4";

export default function Gallery() {
  const [open, setOpen] = useState(false);


  const theme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const images140451 = [
    {
      original: img140451,
      thumbnail: img140451,
    },
    {
      original: img140452,
      thumbnail: img140452,
    },
    {
      original: img140453,
      thumbnail: img140453,
    },
    {
      original: img140454,
      thumbnail: img140454,
    },
    {
      original: img140455,
      thumbnail: img140455,
    },
    {
      original: img140456,
      thumbnail: img140456,
    },
    {
      original: img140457,
      thumbnail: img140457,
    },
    {
      original: img140458,
      thumbnail: img140458,
    },
    {
      original: img140459,
      thumbnail: img140459,
    },
    {
      original: img1404510,
      thumbnail: img1404510,
    },
  ];

  return (
    <>
      <Box name="gallery14045" sx={{ height: "80vh", display: 'flex', alignItems: 'center' }}>
        <Box textAlign="center" sx={{ p: 3, width: "100%", maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
          <ImageGallery items={images140451} showNav={false} />
          <IconButton variant="contained" color="primary" onClick={() => setOpen(true)}><OndemandVideoIcon /></IconButton>
        </Box>
      </Box>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <video autoPlay loop className="galleryVideo">
              <source src={video14045} type="video/mp4" />
            </video>
          </DialogContent>
          <DialogActions>
            <Box align="right">
              <Button onClick={() => setOpen(false)} color="inherit">Close</Button>
            </Box>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
}






import React from 'react';
import { Box, Typography } from "@mui/material";
import { Mouse } from '@mui/icons-material';
import video14045 from "assets/14045.mp4"
import { scroller } from 'react-scroll'

export default function Banner() {

  return (
    <Box name="home" className="banner" style={{ position: 'relative', fontSize: 0 }}>
      <Box className="scroll-to-expore" onClick={() => {
        scroller.scrollTo('about', {
          duration: 500,
          smooth: true,
          offset: -84,
        })
      }}>
        <Box><Mouse /></Box>
        <Typography variant="caption">Scroll to Explore</Typography>
      </Box>
      <video autoPlay loop muted id="video">
        <source src={video14045} type="video/mp4" />
      </video>
    </Box>
  );
}

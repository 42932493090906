import { useState } from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import VisibilitySensor from "react-visibility-sensor";

export default function Right() {
  const [grow, setGrow] = useState(false);

  function onChange(isVisible) {
    if (isVisible) setGrow(true);
    else setGrow(false);
  }

  return (
    <VisibilitySensor onChange={onChange}>
      <Grow
        in={grow}
        style={{ transformOrigin: '0 0 0' }}
        {...(grow ? { timeout: 2000 } : {})}
      >
        <Box sx={{ width: "100%", height: "2px", borderBottom: "2px solid #212121" }} mt={5} />
      </Grow>
    </VisibilitySensor>

  );
}

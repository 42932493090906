import React, { useState } from 'react';
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import img14045 from "assets/14045-1.jpg";
import img80 from "assets/80-1.jpg";
import img113 from "assets/113-1.jpg";
import Gallery80 from "components/Gallery80";
import Gallery113 from "components/Gallery113";
import Gallery1405 from "components/Gallery1405";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Right from "components/Right";
import Left from "components/Left";

export default function About() {
  const [openGallery80, setOpenGallery80] = useState(false);
  const [openGallery113, setOpenGallery113] = useState(false);
  const [openGallery1405, setOpenGallery1405] = useState(false);

  var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Box name="about" sx={{ minHeight: "50vh", paddingBottom: "10vh" }} className="about">
        <Box sx={{ p: 3 }}>
          <br />
          <Box sx={{ width: "100%", maxWidth: 950, textAlign: "center", marginLeft: 'auto', marginRight: 'auto', marginTop: '5vh' }}>
            <Grid container spacing={2}>
              <Grid xs={2}>
              <Left />
              </Grid>
              <Grid xs={8}>
                <Typography variant="h2" fontWeight="light">Art of Outdoor Living.</Typography>
              </Grid>
              <Grid xs={2}>
              <Right />
              </Grid>
            </Grid>
            <Typography variant="h4">Your home isn&apos;t complete without a well-designed outdoor living space &#45; a backdrop for family and friends to share life&apos;s precious moments.</Typography>
          </Box>
        </Box>
      </Box>
      <Box style={{ maxWidth: 1600, margin: "-50px auto 0", paddingBottom: "10vh" }} pl={2} pr={2}>
        <Slider {...settings}>
          <Button onClick={() => {
            setOpenGallery1405(true);
          }}>
            <img src={img14045} alt="Boca Roton, FL" style={{ width: 350, maxWidth: "100%", margin: '0 auto' }} />
            <br />
            <Box style={{
              borderRadius: 4, border: "4px solid #D9D9D9", display: "block",
              maxWidth: 250,
              margin: "0 auto"
            }} p={2}>
              <Typography variant="h6" textAlign="center" color="textSecondary">
                Boca Roton, FL
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => {
              setOpenGallery80(true);
            }}>
            <img src={img80} alt="WaterMill, NY" style={{ width: 350, maxWidth: "100%", margin: '0 auto' }} />
            <br />
            <Box style={{
              borderRadius: 4, border: "4px solid #D9D9D9", display: "block",
              maxWidth: 250,
              margin: "0 auto"
            }} p={2}>
              <Typography variant="h6" textAlign="center" color="textSecondary">
                WaterMill, NY
              </Typography>
            </Box>
          </Button>
          <Button onClick={() => {
            setOpenGallery113(true);
          }}>
            <img src={img113} alt="Atlantic Beach, NY" style={{ width: 350, maxWidth: "100%", margin: '0 auto' }} />
            <br />
            <Box style={{
              borderRadius: 4, border: "4px solid #D9D9D9", display: "block",
              maxWidth: 250,
              margin: "0 auto"
            }} p={2}>
              <Typography variant="h6" textAlign="center" color="textSecondary">
                Atlantic Beach, NY
              </Typography>
            </Box>
          </Button>
        </Slider>
      </Box>

      <Dialog
        fullScreen
        open={openGallery80}
        onClose={() => setOpenGallery80(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenGallery80(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              80 Flying Point Road, WaterMill, NY
            </Typography>
            <Button autoFocus color="inherit" onClick={() => setOpenGallery113(false)}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Gallery80 />
      </Dialog>


      <Dialog
        fullScreen
        open={openGallery113}
        onClose={() => setOpenGallery113(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenGallery113(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              113 Erie Ave., Atlantic Beach, NY
            </Typography>
            <Button autoFocus color="inherit" onClick={() => setOpenGallery113(false)}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Gallery113 />
      </Dialog>

      <Dialog
        fullScreen
        open={openGallery1405}
        onClose={() => setOpenGallery1405(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenGallery1405(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              14045 Brookwod Rd., Boca Roton, FL
            </Typography>
            <Button autoFocus color="inherit" onClick={() => setOpenGallery1405(false)}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Gallery1405 />
      </Dialog>


    </>
  );
}

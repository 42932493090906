import React from 'react';
import { Box, Grid, Typography } from "@mui/material";

export default function Contact() {

  return (
    <>
    <Box name="contact" sx={{ minHeight: "100vh" }} className="contact">
      <Box sx={{ p: 3 }}>
        <Box sx={{ mt: '30px', maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <br />
              <br />
              <br />
              <Typography variant="h5" color="#fff"><strong>Contact Us</strong></Typography>
              <br />
              <Box sx={{width: 50, height: 4, background: '#fff'}} />
              <br />
              <Typography variant="body1" sx={{lineHeight: 1.5}} color="#fff">Text us at: (516) 468-9253</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  </>
  );
}

import { Home as HomeIcon, Image } from '@mui/icons-material';
import Home from 'modules/Home';
import Gallery from "modules/Gallery";

const Public = [
  {
    path: '/',
    Component: Home,
    sidebarName: 'Home',
    icon: HomeIcon,
    menuItem: false
  },
  {
    path: '/gallery',
    Component: Gallery,
    sidebarName: 'Gallery',
    icon: Image,
    menuItem: false
  },
];

export { Public };

import * as React from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CollectionsIcon from '@mui/icons-material/Collections';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { Public } from "routes/Public";
import { scroller } from 'react-scroll'
import logo from 'assets/logo.png';

const drawerWidth = 240;

function PublicLayout(props) {
  const { window } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Toolbar />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={async () => {
            if (location.pathname !== "/")
              await navigate("/");
            scroller.scrollTo('home', {
              duration: 500,
              smooth: true,
              offset: -84,
            })
          }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={async () => {
            if (location.pathname !== "/")
              await navigate("/");
            scroller.scrollTo('about', {
              duration: 500,
              smooth: true,
              offset: -84,
            })
          }}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={async () => {
            if (location.pathname !== "/")
              await navigate("/");
            scroller.scrollTo('process', {
              duration: 500,
              smooth: true,
              offset: -84,
            })
          }}>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Process" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate('/gallery') }}>
            <ListItemIcon>
              <CollectionsIcon />
            </ListItemIcon>
            <ListItemText primary="Gallery" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={async () => {
            if (location.pathname !== "/")
              await navigate("/");
            scroller.scrollTo('contact', {
              duration: 500,
              smooth: true,
              offset: -84,
            })
          }}>
            <ListItemIcon>
              <ConnectWithoutContactIcon />
            </ListItemIcon>
            <ListItemText primary="Contact" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ paddingTop: 40, paddingBottom: 40, background: "#fff" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon style={{ color: "rgba(0,0,0,0.87)" }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{
            position: {
              md:
                'absolute',
              lg:
                'absolute',
              xl:
                'absolute',
            },
            top: {
              md:
                -12,
              lg:
                -12,
              xl:
                -12,
            },
            left: 48
          }}>
            <img src={logo} alt="In Bloom Design Company" style={{ width: 300 }} onClick={async () => {
              if (location.pathname !== "/")
                await navigate("/");
              scroller.scrollTo('home', {
                duration: 500,
                smooth: true,
                offset: -84,
              })
            }} />
          </Typography>
          <List sx={{ flexGrow: 1, textAlign: 'right', color: "#212121", textTransform: "uppercase", fontWeight: '500', fontSize: 20, display: { xs: 'none', lg: 'block' } }}>
            <ListItemButton sx={{ display: 'inline' }} onClick={async () => {
              if (location.pathname !== "/")
                await navigate("/");
              scroller.scrollTo('home', {
                duration: 500,
                smooth: true,
                offset: -84,
              })
            }}>
              Home
            </ListItemButton>
            <ListItemButton sx={{ display: 'inline' }} onClick={async () => {
              if (location.pathname !== "/")
                await navigate("/");
              scroller.scrollTo('about', {
                duration: 500,
                smooth: true,
                offset: -84,
              })
            }}>
              About
            </ListItemButton>
            <ListItemButton sx={{ display: 'inline' }} onClick={async () => {
              if (location.pathname !== "/")
                await navigate("/");
              scroller.scrollTo('process', {
                duration: 500,
                smooth: true,
                offset: -84,
              })
            }}>
              Process
            </ListItemButton>
            <ListItemButton sx={{ display: 'inline' }} onClick={() => { navigate('/gallery') }}>
              Gallery
            </ListItemButton>
            <ListItemButton sx={{ display: 'inline' }} onClick={async () => {
              if (location.pathname !== "/")
                await navigate("/");
              scroller.scrollTo('contact', {
                duration: 500,
                smooth: true,
                offset: -84,
              })
            }}>
              Contact
            </ListItemButton>
          </List>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, maxWidth: "100%" }}
      >
        <Toolbar style={{ paddingBottom: 104 }} />
        <Routes>
          {Public.map(({ path, Component }) => (
            <Route exact path={path} key={path} element={<Component />} />
          ))}
        </Routes>
      </Box>
    </Box>
  );
}

PublicLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PublicLayout;

import { Box, Card, CardContent, Typography } from "@mui/material";

import Gallery80 from "components/Gallery80";
import Gallery113 from "components/Gallery113";
import Gallery1405 from "components/Gallery1405";

export default function Gallery() {

  return (
    <Box style={{ margin: "100px auto 0", maxWidth: 1200 }} pl={2} pt={2}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5">80 Flying Point Road, WaterMill, NY</Typography>
          <Gallery80 />
        </CardContent>
      </Card>
      <br />
      <br />
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5">113 Erie Ave., Atlantic Beach, NY</Typography>
          <Gallery113 />
        </CardContent>
      </Card>
      <br />
      <br />
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5">14045 Brookwod Rd., Boca Roton, FL</Typography>
          <Gallery1405 />
        </CardContent>
      </Card>
      <br />
      <br />
    </Box>
  )
}
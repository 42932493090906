import React from 'react';
import Banner from './components/Banner';
import About from './components/About';
import Process from './components/Process';
import Contact from './components/Contact';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function Home() {
  return (
    <>
      <Banner />
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <About />
        <Process />
        <Contact />
      </ThemeProvider>
    </>
  );
}

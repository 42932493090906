import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import ImageGallery from 'react-image-gallery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import img1131 from "assets/113-1.jpg";
import img1132 from "assets/113-2.jpg";
import img1133 from "assets/113-3.jpg";
import img1134 from "assets/113-4.jpg";
import img1135 from "assets/113-5.jpg";
import img1136 from "assets/113-6.jpg";
import img1137 from "assets/113-7.jpg";
import img1138 from "assets/113-8.jpg";
import img1139 from "assets/113-9.jpg";
import img11310 from "assets/113-10.jpg";
import img11311 from "assets/113-11.jpg";
import img11312 from "assets/113-12.jpg";
import img11313 from "assets/113-13.jpg";
import img11314 from "assets/113-14.jpg";
import img11315 from "assets/113-15.jpg";
import video113 from 'assets/113.mp4';
import "react-image-gallery/styles/css/image-gallery.css";

export default function Gallery() {
  const [open, setOpen] = useState(false);

  const theme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const images113 = [
    {
      original: img1131,
      thumbnail: img1131,
    },
    {
      original: img1132,
      thumbnail: img1132,
    },
    {
      original: img1133,
      thumbnail: img1133,
    },
    {
      original: img1134,
      thumbnail: img1134,
    },
    {
      original: img1135,
      thumbnail: img1135,
    },
    {
      original: img1136,
      thumbnail: img1136,
    },
    {
      original: img1137,
      thumbnail: img1137,
    },
    {
      original: img1138,
      thumbnail: img1138,
    },
    {
      original: img1139,
      thumbnail: img1139,
    },
    {
      original: img11310,
      thumbnail: img11310,
    },
    {
      original: img11311,
      thumbnail: img11311,
    },
    {
      original: img11312,
      thumbnail: img11312,
    },
    {
      original: img11313,
      thumbnail: img11313,
    },
    {
      original: img11314,
      thumbnail: img11314,
    },
    {
      original: img11315,
      thumbnail: img11315,
    },
  ];

  return (
    <>
      <Box name="gallery113" sx={{ height: "80vh", display: 'flex', alignItems: 'center', maxWidth: "100%" }}>
        <Box textAlign="center" sx={{ p: 3, maxWidth: 1200, width: "100%", marginLeft: 'auto', marginRight: 'auto' }}>
          <ImageGallery items={images113} showNav={false} />
          <IconButton variant="contained" color="primary" onClick={()=>setOpen(true)}><OndemandVideoIcon /></IconButton>
        </Box>
      </Box>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <video autoPlay loop className="galleryVideo">
              <source src={video113} type="video/mp4" />
            </video>
          </DialogContent>
          <DialogActions>
            <Box align="right">
              <Button onClick={() => setOpen(false)} color="inherit">Close</Button>
            </Box>
          </DialogActions>
        </Dialog>
      </ThemeProvider>

    </>
  );
}






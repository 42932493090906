import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import Right from "components/Right";
import Left from "components/Left";
import slide1 from "assets/slide1.jpg";
import slide2 from "assets/slide2.jpg";
import slide3 from "assets/slide3.jpg";
import slide4 from "assets/slide4.jpg";
import slide5 from "assets/slide5.jpg";
import slide6 from "assets/slide6.jpg";
import computer from "assets/computer.jpg";
import ruler from "assets/ruler.jpg";
import hydrangea from "assets/hydrangea.jpg";
import house from "assets/house.jpg";
import gardenia from "assets/gardenia.jpg";

export default function Process() {

  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <>
      <Box name="process" sx={{ minHeight: "100vh" }} className="process">
        <Slider {...settings}>
          <img src={slide1} alt="slide1" style={{ width: "350px !important", maxWidth: "100%", margin: '0 auto' }} />
          <img src={slide2} alt="slide2" style={{ width: "350px !important", maxWidth: "100%", margin: '0 auto' }} />
          <img src={slide3} alt="slide3" style={{ width: "350px !important", maxWidth: "100%", margin: '0 auto' }} />
          <img src={slide4} alt="slide4" style={{ width: "350px !important", maxWidth: "100%", margin: '0 auto' }} />
          <img src={slide5} alt="slide5" style={{ width: "350px !important", maxWidth: "100%", margin: '0 auto' }} />
          <img src={slide6} alt="slide6" style={{ width: "350px !important", maxWidth: "100%", margin: '0 auto' }} />
        </Slider>
        <Box sx={{ p: 3 }}>
          <Box sx={{ mt: '30px', maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
            <br />
            <Box sx={{ width: "100%", maxWidth: 950, textAlign: "center", marginLeft: 'auto', marginRight: 'auto', marginTop: '5vh' }}>
              <Grid container spacing={2}>
                <Grid xs={4}>
                  <Left />
                </Grid>
                <Grid xs={4}>
                  <img src={gardenia} alt="In Bloom Logo" style={{ maxWidth: 120 }} />
                </Grid>
                <Grid xs={4}>
                  <Right />
                </Grid>
              </Grid>
              <Typography variant="h2" fontWeight="light">PERFECTION IS IN THE PROCESS.</Typography>
              <Typography variant="h4">Our designs and work are meant to last a lifetime - it all starts with scrupulous attention to detail and a penchant for style.</Typography>
            </Box>
          </Box>
          <br />
          <br />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <Card elevation={3} style={{ background: "#D9D9D9" }}>
                <CardContent>
                  <img src={house} alt="consultation" />
                  <br />
                  <br />
                  <Typography variant="h5" component="div" textAlign="center"><strong>Consultation</strong></Typography>
                  <br />
                  <Typography variant="body2" style={{ minHeight: 90 }}>
                    After you share your ideas and vision for your home, we will conduct an in-depth property assessment – marking all utilities and existing features.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card elevation={3} style={{ background: "#D9D9D9" }}>
                <CardContent>
                  <img src={ruler} alt="site visit" />
                  <br />
                  <br />
                  <Typography variant="h5" component="div" textAlign="center"><strong>Site Visit</strong></Typography>
                  <br />
                  <Typography variant="body2" style={{ minHeight: 90 }}>
                    At this stage we will begin to create a 3D rendering of your property to ensure the interface of your thoughts and vision are consistent with our design team.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card elevation={3} style={{ background: "#D9D9D9" }}>
                <CardContent>
                  <img src={computer} alt="design" />
                  <br />
                  <br />
                  <Typography variant="h5" component="div" textAlign="center"><strong>Design</strong></Typography>
                  <br />
                  <Typography variant="body2" style={{ minHeight: 90 }}>
                    Based on your feedback, we will apply any adjustments to the design to ensure your vision is achieved.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card elevation={3} style={{ background: "#D9D9D9" }}>
                <CardContent>
                  <img src={hydrangea} alt="delivery" />
                  <br />
                  <br />
                  <Typography variant="h5" component="div" textAlign="center"><strong>Delivery</strong></Typography>
                  <br />
                  <Typography variant="body2" style={{ minHeight: 90 }}>
                    Bring your design to life with our team and network of certified contractors – committed to quality and excellence.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
        </Box>
      </Box>
    </>
  );
}
